import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LeadFormModel } from 'src/app/models/LeadForm';
import { QuoteService } from 'src/app/services/quote.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-condition-modal',
  templateUrl: './condition-modal.component.html',
  styleUrls: ['./condition-modal.component.css']
})
export class ConditionModalComponent implements OnInit {
 
  public FormValid: boolean = false;
  public FormErrors: any = null;

  constructor(
    public router: Router,
    public bsModalRef: BsModalRef,
    public FormValues: LeadFormModel = new LeadFormModel,
    public shared: SharedService,
    public _QuoteSerive: QuoteService,
    public fb: FormBuilder
  ) { }

  ngOnInit() {
  }

  checkInsuranceExists(value) {
    let _index = this.FormValues.RetirementPlans.indexOf(value);
    if(_index > -1) {
      return true;
    }
    else {
      return false;
    }
  }

  selectInsurancePlan(event, value) {
    console.log(value);
    let _index = this.FormValues.RetirementPlans.indexOf(value);

    console.log(_index);

    if(event.target.checked && _index == -1) {
    console.log('evt');

      if(value == 'none_of_above') {
        console.log('none');
       
        this.FormValues.resetRetirementplans();
        console.log(this.FormValues.RetirementPlans);
      }
      else {
        // pop none out of health condition array
        console.log('not none');
        console.log(this.FormValues.RetirementPlans);

        let _none_index = this.FormValues.RetirementPlans.indexOf('none_of_value');
        console.log(this.FormValues.RetirementPlans);

        console.log(_none_index);

        if(_none_index == -1 && this.FormValues.RetirementPlans[0]=='none_of_above') {
          this.FormValues.RetirementPlans.splice(_none_index, 1);
          console.log(this.FormValues.RetirementPlans);

        }
      }

      this.FormValues.setRetirementPlans(value);
    }
    else {
      if(_index > -1) {
        this.FormValues.RetirementPlans.splice(_index, 1);
      }
    }
  }

  continueNext() {
    
   
    if(this.FormValues.RetirementPlans.length < 1) {
      this.FormValid = false;
      this.FormErrors = true;
    }
    else {
      this.FormErrors = false;
      document.body.style.overflowY = "scroll";

      let retirement_plans ={
        lead_id: this.FormValues.lead_id,
        retirement_plans: this.FormValues.RetirementPlans
      }
     this._QuoteSerive.saveRetirementPlan(retirement_plans).subscribe(
      result=>{
        console.log(result);
        this.shared.communicator.next(1);
        this.bsModalRef.hide();
      }
    )


    }

    
  }
}
